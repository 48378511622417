// extracted by mini-css-extract-plugin
export var arrow = "heroSlider-module--arrow--BMKOJ";
export var slide = "heroSlider-module--slide--3riMf";
export var play = "heroSlider-module--play--hM1-Z";
export var scrollContainer = "heroSlider-module--scrollContainer--cCx9J";
export var scrollText = "heroSlider-module--scroll-text--xMkXN";
export var alice = "heroSlider-module--alice--3nK6u";
export var slideText = "heroSlider-module--slide-text--qD2UC";
export var item = "heroSlider-module--item--ghwSy";
export var media = "heroSlider-module--media--FHGdH";
export var grid = "heroSlider-module--grid--WQZVj";
export var prev = "heroSlider-module--prev--y82zQ";
export var next = "heroSlider-module--next--y+chn";