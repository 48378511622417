import React, { Fragment, useEffect, useRef, useState } from 'react'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Head,
  ValuesTabs,
  Slider,
  Modal,
  AnimatedWelcome,
  AnimatedVideo,
} from '@components'
//import api from '@api/blog'
import { Link } from 'gatsby'
import * as st from '@assets/styl/home.module.styl'
import * as st2 from '@assets/styl/heroSlider.module.styl'
import { useLanguage } from '../hooks'
import { translatePath } from '../translations'
import bannerReferralCampaign from '@assets/img/banner-campanha-de-indicacao.jpg'
import blogHome01 from '@assets/img/blog-home-01.jpg'
import blogHome02 from '@assets/img/blog-home-02.jpg'
import cn from 'classnames'
import release01 from '@assets/img/release01.jpg'
import release02 from '@assets/img/release02.jpg'
import release03 from '@assets/img/release03.jpg'
import release01Hover from '@assets/img/release01-hover.jpg'
import release02Hover from '@assets/img/release02-hover.jpg'
import release03Hover from '@assets/img/release03-hover.jpg'
import release01MobileHover from '@assets/img/release01-hover-mobile.jpg'
import release02MobileHover from '@assets/img/release02-hover-mobile.jpg'
import release03MobileHover from '@assets/img/release03-hover-mobile.jpg'
import release01Mobile from '@assets/img/release01-mobile.jpg'
import release02Mobile from '@assets/img/release02-mobile.jpg'
import release03Mobile from '@assets/img/release03-mobile.jpg'

import heroBg from '@assets/img/hero-bg.jpg'
import heroBgMobile from '@assets/img/hero-mobile-bg.jpg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      allBlogPost {
        nodes {
          link
          title {
            rendered
          }
          _embedded {
            wp_featuredmedia {
              media_details {
                sizes {
                  post_thumb_big {
                    source_url
                  }
                }
              }
            }
          }
        }
      }
      allNumbers {
        nodes {
          name
          number
          title
        }
      }
      allShowcases {
        nodes {
          id
          image
          imageMobile
          title
          url
          video
          videoMobile
        }
      }
      allReleases {
        nodes {
          facade
          facadeMobile
          facadeHover
          facadeHome
          facadeHomeMobile
          facadeHomeHover
          name
          neighborhood
          city
          room
          tag
          url
          feature
        }
      }
      allPosts {
        nodes {
          title
          site
          url
        }
      }
    }
  `)
  const numbers = data.allNumbers.nodes
  const showcases = data.allShowcases.nodes
  const releases = data.allReleases.nodes
  const posts = data.allPosts.nodes
  const blogPosts = data.allBlogPost.nodes
  const [isMobile, setIsMobile] = useState(false)
  const sectionNumbers = useRef(null)

  const { t } = useLanguage()

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const numbers = gsap.utils.toArray('.numbers') as any

    const animateNumber = (
      element: HTMLElement,
      endValue: number,
      symbols: string[],
      index: number
    ) => {
      const obj = { value: 0 }

      gsap.to(obj, {
        value: endValue,
        duration: 2,
        onUpdate: () => {
          element.innerHTML = `${symbols[index]}${Math.floor(
            obj.value
          ).toLocaleString('de-DE')}`
        },
      })
    }

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionNumbers.current,
        start: '20% center',
        end: 'bottom center',
      },
    })

    numbers.forEach((numberElement: any, index: number) => {
      const formattedValue = numberElement.dataset.value
        .toString()
        .trim()
        .replace(/[^0-9,.]/g, '')

      const numericValue = parseFloat(
        formattedValue.replace(/\./g, '').replace(',', '.')
      )

      const symbols = ['+', '+', '+', '+']

      tl.to(
        numberElement,
        {
          opacity: 1,
          delay: 0.1 * index,
          onStart: () =>
            animateNumber(numberElement, numericValue, symbols, index),
        },
        '<'
      )
    })
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1199)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [numbers])

  console.log(showcases)

  return (
    <>
      <Head pathname="/" />

      <section className={cn(st.hero, 'heroScroll')}>
        <Slider
          classes={{ root: st2.slidesWrapper, arrow: st2.arrow }}
          slidesToShow={1}
          responsiveSize={1199}
          isArrow={showcases.length > 1 ? true : false}
          finite
        >
          {showcases.map((item, index) => (
            <div className={st2.alice} key={index.id}>
              <a
                href={item.url !== ' ' ? item.url : 'javascript:;'}
                target={item.url !== ' ' ? '_blank' : ''}
              >
                <div className={st2.slide}>
                  {item.image ? (
                    <picture className={st2.item}>
                      <source
                        srcSet={item.imageMobile}
                        media="(max-width: 1199px)"
                      />
                      <img
                        src={item.image}
                        alt={item.title}
                        className="media"
                      />
                    </picture>
                  ) : (
                    <div className={st2.item}>
                      <video className={st2.media} autoPlay muted loop>
                        <source src={item.video} type="video/mp4" />
                      </video>
                    </div>
                  )}
                  <div className={st2.grid}>
                    <span className={st2.slideText}>{item.title}</span>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </section>

      <AnimatedWelcome />

      <AnimatedVideo />

      <section className={cn(st.releases, st.releasesEnterprises)}>
        <div className={st.releasesContainer}>
          <h2>{t('Home.releases')}</h2>
          <p>
            {t('Home.releasesText')
              .split('\n')
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < t('Home.releasesText').split('\n').length - 1 && (
                    <br />
                  )}
                </React.Fragment>
              ))}
          </p>

          {isMobile && (
            <Link
              to={translatePath('/empreendimentos/lancamentos/')}
              className="btn spacing-mobile"
            >
              {t('Home.moreEnterprises')}
            </Link>
          )}

          <ul>
            {releases.map((release, index) => {
              const imageDesktop =
                index === 0 ? release.facade : release.facadeHome
              const imageMobile =
                index === 0 ? release.facadeMobile : release.facadeHomeMobile
              const imageHover =
                index === 0 ? release.facadeHover : release.facadeHomeHover

              return (
                <li key={index} className={index === 0 ? '' : st.smallerList}>
                  <div>
                    <div className={st.imageWrapper}>
                      {/* Imagem principal */}
                      <img
                        src={isMobile ? imageMobile : imageDesktop}
                        alt="Release facade"
                        className={index === 0 ? '' : st.smallerImage}
                      />
                      {/* Imagem de hover */}
                      {release.facadeHomeHover || release.facadeHover ? (
                        <img
                          src={imageHover}
                          alt="Release facade hover"
                          className={cn(
                            st.animationHover,
                            index === 0 ? '' : st.smallerImage
                          )}
                        />
                      ) : null}
                    </div>

                    <div>
                      <h4>{release.name}</h4>
                      <p>
                        {release.neighborhood} / {release.city}
                      </p>
                    </div>
                  </div>
                  <a
                      href={release.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    ></a>
                </li>
              )
            })}
          </ul>

          {!isMobile && (
            <Link
              to={translatePath('/empreendimentos/lancamentos/')}
              className="btn spacing"
            >
              {t('Home.moreEnterprises')}
            </Link>
          )}
        </div>
      </section>

      <section className={st.numbersContainer} ref={sectionNumbers}>
        <div>
          <h2>
            {t('Home.numbers')
              .split('\n')
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < t('Home.numbers').split('\n').length - 1 && <br />}
                </React.Fragment>
              ))}
          </h2>
          <p>
            {t('Home.numbersText')
              .split('\n')
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < t('Home.numbersText').split('\n').length - 1 && (
                    <br />
                  )}
                </React.Fragment>
              ))}
          </p>
          <Link
            to={translatePath('/empreendimentos/entregues/')}
            className={cn('btn-white', isMobile ? 'spacing-mobile' : 'spacing')}
          >
            {t('Home.discoverOurPortfolio')}
          </Link>
          <ul className={st.numbers}>
            {numbers.map((number, key) => (
              <li key={key}>
                <div>
                  <div data-value={number.number} className="numbers">
                    0
                  </div>
                  {number.title}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {Boolean(posts && posts.length) && (
        <section className={st.blog}>
          <div>
            <h2>{t('Home.intheMedia')}</h2>
            <Slider
              classes={{ root: st.slidesWrapper, arrow: st.arrow }}
              dots
              slidesToShow={3}
              responsiveSize={1199}
            >
              {posts.map((post, key) => (
                <a
                  href={post.url}
                  target="_blank"
                  key={key}
                  className={st.slide}
                  itemScope
                  itemType="http://schema.org/BlogPosting"
                >
                  <meta itemProp="url" content={post.url} />
                  <span itemProp="publisher">{post.site}</span>
                  <h4 itemProp="name">{post.title}</h4>
                </a>
              ))}
            </Slider>
          </div>
        </section>
      )}

      <section className={st.blogDimasContainer}>
        <h2>Blog Dimas</h2>
        <div>
          {blogPosts.map((item, index) => {
            const image =
              item._embedded['wp_featuredmedia'][0].media_details.sizes
                .post_thumb_big.source_url || ''
            return (
              <div key={index} className={st.blogDimas}>
                <figure>
                  <img
                    src={image}
                    alt={item.title.rendered}
                    width={543}
                    height={335}
                  />
                </figure>
                <h3>{item.title.rendered}</h3>
                <a
                  href={item.link}
                  target="_blank"
                  title=""
                  className={st.effect}
                ></a>
              </div>
            )
          })}
        </div>
        <Link
          to={translatePath('https://dimasconstrucoes.com.br/blog/')}
          target="_blank"
          className="btn"
        >
          {t('Home.blogDimasLink')}
        </Link>
      </section>
    </>
  )
}

export default Home
